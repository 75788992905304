// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "../../../../../utility/hooks/useAxios"

export const getTasks = createAsyncThunk("appTodo/getTasks", async (params) => {

    // http://85.31.225.134/api/listuploaddata?filter=&q=&sortBy=&tag=
    const response = await axios.get("/listuploaddata", { params })

    let nilai = response.data

    return {
        params,
        data: nilai.data,
    }
})



export const appTodoSlice = createSlice({
    name: "appTodo",
    initialState: {
        tasks: [],
        selectedTask: {},
        params: {
            filter: "",
            q: "",
            sort: "",
            tag: "",
        },
    },
    reducers: {
        reOrderTasks: (state, action) => {
            state.tasks = action.payload
        },
        selectTask: (state, action) => {
            state.selectedTask = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTasks.fulfilled, (state, action) => {
            state.tasks = action.payload.data
            state.params = action.payload.params
        })
    },
})

export const { reOrderTasks, selectTask } = appTodoSlice.actions

export default appTodoSlice.reducer
