import axios from "axios"

const BASE_URL = process.env.REACT_APP_API
const BASE_URL_LUAR = process.env.REACT_APP_API_LUAR

let location_http = window.location.href

// console.log("location_http:", location_http)
var splitURL = location_http.toString().split("/")

let path_link = splitURL[2]

// console.log(path_link.substring(0, 3))
// console.log("path_link.substring(0, 3):", path_link.substring(0, 3))

let __BaseURL = path_link.substring(0, 3) === "103" ? BASE_URL_LUAR : BASE_URL

export default axios.create({
  baseURL: __BaseURL,
  headers: { "Content-Type": "application/json" },
  // withCredentials: true,
})

export const axiosPrivate = axios.create({
  baseURL: __BaseURL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
})
