// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from "../../../../utility/hooks/useAxios"

export const getClients = createAsyncThunk(
  "appEcommerce/getClients",
  async (params) => {
    const response = await axios.get("/products", { params })
    console.log("params----------------------------------->>>:", params)
    console.log("response client", response)
    return { params, data: response.data }
  }
)

export const getVendors = createAsyncThunk(
  "appEcommerce/getVendor",
  async () => {
    const response = await axios.get("/categories")
    console.log("response vendor", response.data)
    return { data: response.data }
  }
)

export const getClient = createAsyncThunk(
  "appEcommerce/getClient",
  async (slug) => {
    const response = await axios.get(`/product/${slug}`)
    return response.data
  }
)

export const appEClientSlice = createSlice({
  name: "appEcommerce",
  initialState: {
    params: {},
    clients: [],
    vendors: [],
    totalClients: 0,
    clientDetail: {},
    selectedCompany: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClients.fulfilled, (state, action) => {
        state.params = action.payload.params
        state.clients = action.payload.data.products
        state.totalClients = action.payload.data.totalRows
      })
      .addCase(getVendors.fulfilled, (state, action) => {
        console.log("Asdasdas:", action.payload)
        state.vendors = action.payload.data.categories
      })
      .addCase(getClient.fulfilled, (state, action) => {
        console.log(action.payload.client)
        state.clientDetail = action.payload.client
      })
  },
})

export default appEClientSlice.reducer
